<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/unit/reseau/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加网格</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="网格名" prop="name">
					<el-input v-model="form.name" placeholder="请输入网格名"></el-input>
				</el-form-item>
				<el-form-item label="单位">
					<el-select @change="handleFirstChange" v-model="form.first_id" placeholder="请选择支队">
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="form.second_id" placeholder="请选择大队">
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleThirdChange" v-model="form.third_id" placeholder="请请选择街道">
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				firstList: [],
				secondList: [],
				thirdList: [],
				form: {
					name: '',
					first_id: '',
					second_id: '',
					third_id: '',
					first_unit_name: '',
					second_unit_name: '',
					third_unit_name: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入网格名',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getUnitList()
		},
		methods: {
			handleFirstChange(first_id) {
				this.form.second_id = '';
				this.form.third_id = '';
				this.form.second_unit_name = '';
				this.form.third_unit_name = '';
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
						this.form.first_unit_name = this.firstList[i].unit_name;
					}
				}
			},
			handleSecondChange(second_id) {
				this.form.third_id = '';
				this.form.third_unit_name = '';
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
						this.form.second_unit_name = this.secondList[i].unit_name;
					}
				}
			},
			handleThirdChange(third_id) {
				for (let i = 0; i < this.thirdList.length; i++) {
					if (this.thirdList[i]['unit_id'] == third_id) {
						this.form.third_unit_name = this.thirdList[i].unit_name;
					}
				}
			},
			
			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/unit.reseau/add',
							},
							data: {
								reseau: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'unit.reseau/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
